import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(nuxtApp => {
  const { vueApp } = nuxtApp;

  Sentry.init({
    app: [vueApp],
    dsn: 'https://631e7ecac6b84811ab4beb38ab716cde@o446669.ingest.sentry.io/4504450775252992',
    environment: process.dev ? 'development' : 'production',
    release: process.env.GLOBAL_O7RELEASE,
    integrations: [
      Sentry.browserTracingIntegration({ router: useRouter() })
    ],
    tracesSampleRate: 0.0025,
    ignoreErrors: [
      '"name":"adsManager"',
      '"name":"isGptPresent"',
      '"name":"activityMonitor"',
      '"name":"adsLoader"',
      '"name":"gpt"'
    ],
    beforeSend (event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
      }
      // Continue sending to Sentry
      return event;
    }
  });

  vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }));
  Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  };
});
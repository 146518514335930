import Plausible, { type EventOptions, type PlausibleOptions } from 'plausible-tracker';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(() => {
  const options = {
    domain: 'talkingtomandfriends.com',
    hashMode: true,
    trackLocalhost: false
  };

  const { enableAutoPageviews, trackPageview, trackEvent } = Plausible(options);

  return {
    provide: {
      plausible: () => {
        enableAutoPageviews();
    
        // target blank stops working afters this, also age gate…
        // enableAutoOutboundTracking();
      },
    
      event: (eventName: string, options?: EventOptions, eventData?: PlausibleOptions) => {
        trackEvent(eventName, options, eventData);
      },
      
      pageview: (eventData?: PlausibleOptions, options?: EventOptions) => {
        trackPageview(eventData, options);
      }
    }
  };
});

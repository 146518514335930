import '@outfit7/o7compliance-client/dist/style.css';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { o7complianceInit, Collector } from '@outfit7/o7compliance-client';


import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(VueQueryPlugin);
  nuxtApp.vueApp.use(o7complianceInit, {
    collectors: [Collector.AgeGate]
  });
});

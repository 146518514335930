import useGeoIp from '~/composables/useGeoIp';
import { RegulatoryRegion } from '~/enums';

let isWonderpushEnabled: boolean;
const { checkCountryEligibility } = useGeoIp();

function initialize() {
  if (!isWonderpushEnabled) return;
  useHead({
    script: [
      {
        src: 'https://cdn.by.wonderpush.com/sdk/1.1/wonderpush-loader.min.js',
        async: true,
        crossorigin: 'anonymous'
      }
    ]
  });

  
  onMounted(() => {
    nextTick(async () => {
      // @ts-expect-error (Wonderpush does not provide types)
      window.WonderPush = window.WonderPush || [];
      // @ts-expect-error (Wonderpush does not provide types)
      WonderPush.push(['init', {
        webKey: 'f02748f4c20ea15b18b023b1302b1845b88107e57f2c16c0dc64af8839ecaa43',
        requiresUserConsent: await checkCountryEligibility(RegulatoryRegion.GDPR)
      }]);
    });
  });
}

function setUserConsent(value: boolean) {
  if (!isWonderpushEnabled) return;
  console.log('wonderpush' + isWonderpushEnabled);
  
  // @ts-expect-error (Wonderpush does not provide types)
  window.WonderPush = window.WonderPush || [];
  // @ts-expect-error (Wonderpush does not provide types)
  WonderPush.push(['setUserConsent', value]);
}

function subscribeToNotifications() {
  if (!isWonderpushEnabled) return;
  // @ts-expect-error (Wonderpush does not provide types)
  window.WonderPush = window.WonderPush || [];
  // @ts-expect-error (Wonderpush does not provide types)
  WonderPush.push(['subscribeToNotifications']);
}

export function useWonderpush() {
  isWonderpushEnabled = useRuntimeConfig().public.isWonderpushEnabled;

  return {
    initialize,
    setUserConsent,
    subscribeToNotifications
  };
}

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M8 2C9.17433 2 10.3223 2.34823 11.2987 3.00065C12.2751 3.65307 13.0361 4.58038 13.4855 5.66532C13.9349 6.75025 14.0525 7.94409 13.8234 9.09585C13.5943 10.2476 13.0288 11.3056 12.1984 12.1359C11.3681 12.9663 10.3101 13.5318 9.15835 13.7609C8.00659 13.99 6.81276 13.8724 5.72782 13.423C4.64288 12.9736 3.71557 12.2126 3.06315 11.2362C2.41073 10.2598 2.0625 9.11183 2.0625 7.9375C2.06416 6.36329 2.69025 4.85402 3.80338 3.74088C4.91652 2.62774 6.42579 2.00165 8 2ZM8 1.1875C6.66498 1.1875 5.35994 1.58338 4.2499 2.32508C3.13987 3.06678 2.27471 4.12099 1.76382 5.35439C1.25292 6.58779 1.11925 7.94499 1.3797 9.25436C1.64015 10.5637 2.28303 11.7665 3.22703 12.7105C4.17104 13.6545 5.37377 14.2973 6.68314 14.5578C7.99252 14.8183 9.34971 14.6846 10.5831 14.1737C11.8165 13.6628 12.8707 12.7976 13.6124 11.6876C14.3541 10.5776 14.75 9.27252 14.75 7.9375C14.75 6.14729 14.0388 4.4304 12.773 3.16453C11.5071 1.89866 9.79021 1.1875 8 1.1875Z",
      fill: "currentColor"
    }),
    _createElementVNode("path", {
      d: "M8.13383 2C8.39508 2 8.85633 2.4575 9.25008 3.46125C9.71383 4.64375 9.96883 6.23375 9.96883 7.9375C9.96883 9.64125 9.71883 11.2312 9.25008 12.4137C8.85633 13.4137 8.39633 13.875 8.13383 13.875C7.87133 13.875 7.41133 13.4175 7.01758 12.4137C6.55508 11.2312 6.29883 9.64125 6.29883 7.9375C6.29883 6.23375 6.54883 4.64375 7.01758 3.46125C7.41258 2.45625 7.87383 2 8.13383 2ZM8.13383 1.1875C6.67133 1.1875 5.48633 4.21 5.48633 7.9375C5.48633 11.665 6.67133 14.6875 8.13383 14.6875C9.59633 14.6875 10.7813 11.665 10.7813 7.9375C10.7813 4.21 9.59633 1.1875 8.13383 1.1875Z",
      fill: "currentColor"
    }),
    _createElementVNode("path", {
      d: "M2.20703 10.0469H14.062M2.20703 5.82812H14.062",
      stroke: "currentColor",
      "stroke-width": "0.797991",
      "stroke-miterlimit": "10"
    })
  ]))
}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#clip0_516_1084)" }, [
      _createElementVNode("path", {
        d: "M10 3.33334V11.3028M10 11.3028L14.6924 7.61042M10 11.3028L5.3076 7.61042",
        stroke: "currentcolor",
        "stroke-width": "1.64"
      }),
      _createElementVNode("path", {
        d: "M4.32126 12.5817V14.8754C4.32126 15.4276 4.76897 15.8754 5.32126 15.8754H14.6787C15.231 15.8754 15.6787 15.4276 15.6787 14.8754V12.5817",
        stroke: "currentcolor",
        "stroke-width": "1.64"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "clip0_516_1084" }, [
        _createElementVNode("rect", {
          width: "20",
          height: "20",
          fill: "none"
        })
      ])
    ])
  ]))
}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 56 56",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M46.38 24.42a17.1 17.1 0 0 1-10.08-3.25V36c0 2.75-.83 5.43-2.38 7.69a13.36 13.36 0 0 1-6.28 4.95 13.2 13.2 0 0 1-14.74-3.78 13.57 13.57 0 0 1 4.04-20.9 13.2 13.2 0 0 1 7.85-1.33v7.46a6.06 6.06 0 0 0-6.81 2.3 6.24 6.24 0 0 0 3.1 9.51 6.06 6.06 0 0 0 6.84-2.26A6.24 6.24 0 0 0 29.08 36V7h7.22a10.2 10.2 0 0 0 10.07 10.17v7.25Z",
      fill: "currentColor"
    }),
    _createElementVNode("path", {
      d: "M46.38 24.42a17.1 17.1 0 0 1-10.08-3.25V36c0 2.75-.83 5.43-2.38 7.69a13.36 13.36 0 0 1-6.28 4.95 13.2 13.2 0 0 1-14.74-3.78 13.57 13.57 0 0 1 4.04-20.9 13.2 13.2 0 0 1 7.85-1.33v7.46a6.06 6.06 0 0 0-6.81 2.3 6.24 6.24 0 0 0 3.1 9.51 6.06 6.06 0 0 0 6.84-2.26A6.24 6.24 0 0 0 29.08 36V7h7.22a10.2 10.2 0 0 0 10.07 10.17v7.25Z",
      fill: "currentColor"
    })
  ]))
}
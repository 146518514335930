<template>
  <NuxtLayout>
    <div class="pt-[16vw] md:pt-[5vw] bg-red text-white font-work tracking-[-0.01em] text-center md:text-left">
      <div class="flex-col pt-[14vw] flex md:flex-row justify-between items-center min-h-[calc(100vh-16vw)] md:min-h-[calc(100vh-5vw)] h-full md:px-[13vw] md:py-[5vw] px-[4vw]">
        <div class="md:max-w-[32vw] w-full">
          <div class="uppercase font-oswald font-medium md:text-[1.5vw] md:leading-[1.75vw] md:mb-[1vw] text-[6vw] leading-[7vw] mb-[4vw]">
            Error 404
          </div>
          <h1 class="text-[12vw] md:text-[5vw] leading-none md:mb-[1.5vw] font-extrabold mb-[4vw]">
            Hmm, this is strange…
          </h1>
          <p class="md:text-[1.5vw] md:leading-[2vw] text-[6vw] leading-[8vw]">
            The page you’re looking for doesn’t exist.
          </p>
          <div v-if="!route.path.startsWith('/customs-privacy')"
               class="md:mt-[2.5vw] justify-center md:justify-start flex mt-[6vw]"
          >
            <ButtonRegular class="md:self-start"
                           color="indigo"
                           background-color="white"
                           href="/"
            >
              Back to homepage
            </ButtonRegular>
          </div>
        </div>
        <div class="my-[16vw] md:my-0">
          <NuxtImg format="auto"
                   src="https://cdn-ttf.o7web.com/assets/public/img/404.png"
                   class="md:h-[27.5vw] h-[62vw]"
                   alt="404"
                   sizes="xs:66vw sm:66vw md:50vw lg:50vw xl:50vw xxl:50vw"
          />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
<script setup lang="ts">
import { useRouter, useRoute, clearError } from '#imports';
import { type RouteLocationNormalized } from 'vue-router';

const router = useRouter();
const route = useRoute();

const navigationGuard = (to: RouteLocationNormalized) => {
  unregisterNavigationGuard();
  clearError({ redirect: to.fullPath });
};

const unregisterNavigationGuard = router.beforeEach(navigationGuard);
</script>
// used to get country code from BE /countries
// returns GeoIpData
import * as Sentry from '@sentry/vue';

import { ref, readonly } from 'vue';
import { RegulatoryRegion } from '~/enums';

interface Country {
  key: string;
  label: string;
}

type EligibleCountries = Array<Country>;

const eeaCountries: Array<Country> = [
  { key: 'AT', label: 'Austria' },
  { key: 'BE', label: 'Belgium' },
  { key: 'BG', label: 'Bulgaria' },
  { key: 'HR', label: 'Croatia' },
  { key: 'CY', label: 'Cyprus' },
  { key: 'CZ', label: 'Czech Republic' },
  { key: 'DK', label: 'Denmark' },
  { key: 'EE', label: 'Estonia' },
  { key: 'FI', label: 'Finland' },
  { key: 'FR', label: 'France' },
  { key: 'DE', label: 'Germany' },
  { key: 'EL', label: 'Greece' },
  { key: 'HU', label: 'Hungary' },
  { key: 'IS', label: 'Iceland' },
  { key: 'IE', label: 'Ireland' },
  { key: 'IT', label: 'Italy' },
  { key: 'LV', label: 'Latvia' },
  { key: 'LI', label: 'Liechtenstein' },
  { key: 'LT', label: 'Lithuania' },
  { key: 'LU', label: 'Luxembourg' },
  { key: 'MT', label: 'Malta' },
  { key: 'NL', label: 'Netherlands' },
  { key: 'NO', label: 'Norway' },
  { key: 'PL', label: 'Poland' },
  { key: 'PT', label: 'Portugal' },
  { key: 'RO', label: 'Romania' },
  { key: 'SK', label: 'Slovakia' },
  { key: 'SI', label: 'Slovenia' },
  { key: 'ES', label: 'Spain' },
  { key: 'SE', label: 'Sweden' }
];

const geoIpCountry = ref<string>(null); // SI, DE ...
const geoIpIsFetching = ref(false);
const hasError = ref<any>(null);

// This promise serves as a cache to enable awaiting of multiple geoLocate calls
// from different points in the app and also de-duplicating geoIp fetching.
let geoLocatePromise: Promise<typeof geoIpCountry> = null;

async function geoLocate() {
  if (geoLocatePromise) return geoLocatePromise;

  geoIpIsFetching.value = true;

  geoLocatePromise = fetch('https://apps.outfit7.com/rest/geoip/v1/countries/jsonp').then(async response => {
    if(response.ok) {
      const data = await response.text();
      const match = data.match(/geoip\(\{"country":"([A-Z]{2})"\}\);/);
              
      if (match) {
        geoIpCountry.value = match[1] ?? null;
        return geoIpCountry;
      } else {
        Sentry.captureMessage(data);
        geoIpCountry.value = null;
        return geoLocatePromise = null;
      }
    } else {
      Sentry.captureMessage('Failed to fetch geoIpCountry');
      geoIpCountry.value = null;
      return geoLocatePromise = null;
    }
  }).catch(error => {
    geoIpCountry.value = null;
    hasError.value = error;
    return geoLocatePromise = null;
  }).finally(() => {
    geoIpIsFetching.value = false;
  });

  return geoLocatePromise;
}

async function checkCountryEligibility(criteria: RegulatoryRegion | EligibleCountries): Promise<boolean> {
  await geoLocate();

  if (criteria === RegulatoryRegion.EEA) return eeaCountries.some(country => country.key === geoIpCountry.value);
  if (criteria === RegulatoryRegion.GDPR) return geoIpCountry.value === 'GB' || eeaCountries.some(country => country.key === geoIpCountry.value);
  return criteria.some(country => country.key === geoIpCountry.value);
}

export default function useGeoIp() {
  return { 
    geoIpCountry: readonly(geoIpCountry), 
    geoIpIsFetching: readonly(geoIpIsFetching), 
    hasError: readonly(hasError),
    geoLocate,
    checkCountryEligibility
  };
}

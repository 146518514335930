export enum RegulatoryRegion {
  EEA,
  GDPR
}

export enum ButtonStoreType {
  APPLE = 'apple',
  GOOGLE = 'google',
  HUAWEI = 'huawei',
  AMAZON = 'amazon',
  ARCADE = 'arcade'
}
import { default as _91_46_46_46slug_93tTVZEUyhkLMeta } from "/home/circleci/project/pages/[...slug].vue?macro=true";
import { default as my_45talking_45hankvoBxG3Sv5XMeta } from "/home/circleci/project/pages/apps/my-talking-hank.vue?macro=true";
import { default as _91slug_93lsX9HBvOL4Meta } from "/home/circleci/project/pages/blog/[slug].vue?macro=true";
import { default as en7DbqObtWQXMeta } from "/home/circleci/project/pages/cn-privacypolicy-children/en.vue?macro=true";
import { default as zh_45simphttgeMucG2Meta } from "/home/circleci/project/pages/cn-privacypolicy-children/zh-simp.vue?macro=true";
import { default as creditsdhnzciiAjFMeta } from "/home/circleci/project/pages/credits.vue?macro=true";
import { default as indexbVK8ERtDnaMeta } from "/home/circleci/project/pages/index.vue?macro=true";
import { default as giveaway_45winnerspDjOigMrgpMeta } from "/home/circleci/project/pages/my-talking-hank-islands/giveaway-winners.vue?macro=true";
import { default as deXPRUziNhO4Meta } from "/home/circleci/project/pages/privacy-policy-for-children/de.vue?macro=true";
import { default as entQEwCuxsiIMeta } from "/home/circleci/project/pages/privacy-policy-for-children/en.vue?macro=true";
import { default as esfHlZzrCZk6Meta } from "/home/circleci/project/pages/privacy-policy-for-children/es.vue?macro=true";
import { default as fr3m7s6S7mUVMeta } from "/home/circleci/project/pages/privacy-policy-for-children/fr.vue?macro=true";
import { default as itaRwqDENddRMeta } from "/home/circleci/project/pages/privacy-policy-for-children/it.vue?macro=true";
import { default as nl6b3RkqkHLLMeta } from "/home/circleci/project/pages/privacy-policy-for-children/nl.vue?macro=true";
import { default as ptoAocge3bDWMeta } from "/home/circleci/project/pages/privacy-policy-for-children/pt.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93tTVZEUyhkLMeta?.name ?? "slug",
    path: _91_46_46_46slug_93tTVZEUyhkLMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93tTVZEUyhkLMeta || {},
    alias: _91_46_46_46slug_93tTVZEUyhkLMeta?.alias || [],
    redirect: _91_46_46_46slug_93tTVZEUyhkLMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45talking_45hankvoBxG3Sv5XMeta?.name ?? "apps-my-talking-hank",
    path: my_45talking_45hankvoBxG3Sv5XMeta?.path ?? "/apps/my-talking-hank",
    meta: my_45talking_45hankvoBxG3Sv5XMeta || {},
    alias: my_45talking_45hankvoBxG3Sv5XMeta?.alias || [],
    redirect: my_45talking_45hankvoBxG3Sv5XMeta?.redirect,
    component: () => import("/home/circleci/project/pages/apps/my-talking-hank.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lsX9HBvOL4Meta?.name ?? "blog-slug",
    path: _91slug_93lsX9HBvOL4Meta?.path ?? "/blog/:slug()",
    meta: _91slug_93lsX9HBvOL4Meta || {},
    alias: _91slug_93lsX9HBvOL4Meta?.alias || [],
    redirect: _91slug_93lsX9HBvOL4Meta?.redirect,
    component: () => import("/home/circleci/project/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: en7DbqObtWQXMeta?.name ?? "cn-privacypolicy-children-en",
    path: en7DbqObtWQXMeta?.path ?? "/cn-privacypolicy-children/en",
    meta: en7DbqObtWQXMeta || {},
    alias: en7DbqObtWQXMeta?.alias || [],
    redirect: en7DbqObtWQXMeta?.redirect,
    component: () => import("/home/circleci/project/pages/cn-privacypolicy-children/en.vue").then(m => m.default || m)
  },
  {
    name: zh_45simphttgeMucG2Meta?.name ?? "cn-privacypolicy-children-zh-simp",
    path: zh_45simphttgeMucG2Meta?.path ?? "/cn-privacypolicy-children/zh-simp",
    meta: zh_45simphttgeMucG2Meta || {},
    alias: zh_45simphttgeMucG2Meta?.alias || [],
    redirect: zh_45simphttgeMucG2Meta?.redirect,
    component: () => import("/home/circleci/project/pages/cn-privacypolicy-children/zh-simp.vue").then(m => m.default || m)
  },
  {
    name: creditsdhnzciiAjFMeta?.name ?? "credits",
    path: creditsdhnzciiAjFMeta?.path ?? "/credits",
    meta: creditsdhnzciiAjFMeta || {},
    alias: creditsdhnzciiAjFMeta?.alias || [],
    redirect: creditsdhnzciiAjFMeta?.redirect,
    component: () => import("/home/circleci/project/pages/credits.vue").then(m => m.default || m)
  },
  {
    name: indexbVK8ERtDnaMeta?.name ?? "index",
    path: indexbVK8ERtDnaMeta?.path ?? "/",
    meta: indexbVK8ERtDnaMeta || {},
    alias: indexbVK8ERtDnaMeta?.alias || [],
    redirect: indexbVK8ERtDnaMeta?.redirect,
    component: () => import("/home/circleci/project/pages/index.vue").then(m => m.default || m)
  },
  {
    name: giveaway_45winnerspDjOigMrgpMeta?.name ?? "my-talking-hank-islands-giveaway-winners",
    path: giveaway_45winnerspDjOigMrgpMeta?.path ?? "/my-talking-hank-islands/giveaway-winners",
    meta: giveaway_45winnerspDjOigMrgpMeta || {},
    alias: giveaway_45winnerspDjOigMrgpMeta?.alias || [],
    redirect: giveaway_45winnerspDjOigMrgpMeta?.redirect,
    component: () => import("/home/circleci/project/pages/my-talking-hank-islands/giveaway-winners.vue").then(m => m.default || m)
  },
  {
    name: deXPRUziNhO4Meta?.name ?? "privacy-policy-for-children-de",
    path: deXPRUziNhO4Meta?.path ?? "/privacy-policy-for-children/de",
    meta: deXPRUziNhO4Meta || {},
    alias: deXPRUziNhO4Meta?.alias || [],
    redirect: deXPRUziNhO4Meta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-for-children/de.vue").then(m => m.default || m)
  },
  {
    name: entQEwCuxsiIMeta?.name ?? "privacy-policy-for-children-en",
    path: entQEwCuxsiIMeta?.path ?? "/privacy-policy-for-children/en",
    meta: entQEwCuxsiIMeta || {},
    alias: entQEwCuxsiIMeta?.alias || [],
    redirect: entQEwCuxsiIMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-for-children/en.vue").then(m => m.default || m)
  },
  {
    name: esfHlZzrCZk6Meta?.name ?? "privacy-policy-for-children-es",
    path: esfHlZzrCZk6Meta?.path ?? "/privacy-policy-for-children/es",
    meta: esfHlZzrCZk6Meta || {},
    alias: esfHlZzrCZk6Meta?.alias || [],
    redirect: esfHlZzrCZk6Meta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-for-children/es.vue").then(m => m.default || m)
  },
  {
    name: fr3m7s6S7mUVMeta?.name ?? "privacy-policy-for-children-fr",
    path: fr3m7s6S7mUVMeta?.path ?? "/privacy-policy-for-children/fr",
    meta: fr3m7s6S7mUVMeta || {},
    alias: fr3m7s6S7mUVMeta?.alias || [],
    redirect: fr3m7s6S7mUVMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-for-children/fr.vue").then(m => m.default || m)
  },
  {
    name: itaRwqDENddRMeta?.name ?? "privacy-policy-for-children-it",
    path: itaRwqDENddRMeta?.path ?? "/privacy-policy-for-children/it",
    meta: itaRwqDENddRMeta || {},
    alias: itaRwqDENddRMeta?.alias || [],
    redirect: itaRwqDENddRMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-for-children/it.vue").then(m => m.default || m)
  },
  {
    name: nl6b3RkqkHLLMeta?.name ?? "privacy-policy-for-children-nl",
    path: nl6b3RkqkHLLMeta?.path ?? "/privacy-policy-for-children/nl",
    meta: nl6b3RkqkHLLMeta || {},
    alias: nl6b3RkqkHLLMeta?.alias || [],
    redirect: nl6b3RkqkHLLMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-for-children/nl.vue").then(m => m.default || m)
  },
  {
    name: ptoAocge3bDWMeta?.name ?? "privacy-policy-for-children-pt",
    path: ptoAocge3bDWMeta?.path ?? "/privacy-policy-for-children/pt",
    meta: ptoAocge3bDWMeta || {},
    alias: ptoAocge3bDWMeta?.alias || [],
    redirect: ptoAocge3bDWMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-for-children/pt.vue").then(m => m.default || m)
  }
]
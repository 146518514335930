<template>
  <Head>
    <Link rel="icon"
          type="image/x-icon"
          href="/favicon.png"
    />
  </Head>
  <!-- <VitePwaManifest /> -->
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useNuxtApp, onMounted } from '#imports';
import useTrackScrollDepth from '~~/composables/track-scroll-depth';
import { useWonderpush } from './composables/use-wonderpush';

import '~/assets/styles/main.css';

const { $plausible } = useNuxtApp();
const { initialize: initializeWonderpush } = useWonderpush();
useTrackScrollDepth();

initializeWonderpush();

onMounted(() => {
  $plausible();
});
</script>

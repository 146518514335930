<template>
  <NuxtLink :href="href"
            :target="external ? '_blank' : ''"
            @click="handleClick"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import { useState } from '#imports';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { complianceResultState } from '@outfit7/o7compliance-client';

const router = useRouter();

type GatedLinkProps = {
  href: string;
}

const props = defineProps<GatedLinkProps>();
const ageGateShow = useState('age-gate-show', () => false);
const ageGateLink = useState('age-gate-link', () => undefined);

const external = computed(() => {
  return props.href && props.href.startsWith('http');
});

function handleClick(e: Event) {
  // Did we pass the age gate?
  if (complianceResultState.O7Compliance_AgeLimitPassed === true) {
    return;
  }

  e.preventDefault();

  // Did we fail the age gate?
  if (complianceResultState.O7Compliance_AgeLimitPassed === false) {
    return;
  }

  if (external.value) {
    ageGateShow.value = true;
    ageGateLink.value = props.href;
    return;
  }

  router.push(props.href);
}
</script>
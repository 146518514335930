<template>
  <div>
    <div class="privacy-policy-children">
      <div class="banner">
        <NuxtLink class="cta-play-now"
                  to="/apps/my-talking-tom-friends"
        >
          play now
        </NuxtLink>
      </div>
      <div class="content-wrapper">
        <h1>{{ privacyData.missing.m6 }}</h1>
        <!--
         RED CARD
        -->
        <!-- tab toggler -->
        <div class="flex flex-row main-toggler">
          <div class="flex flex-row wrapper"
               @click="mainToggler"
          >
            <template v-if="toggler">
              <div class="">
                {{ privacyData.x36 }}
              </div>
            </template>
            <template v-else>
              <div class="">
                {{ privacyData.x35 }}
              </div>
            </template>
            <!-- arrow toggle -->
            <img class="arrow-toggler"
                 src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Group-72.png"
                 alt=""
            >
          </div>
        </div>
        <div class="card introduction-card">
          <div class="card-header">
            <h2>{{ privacyData.t1 }}</h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t2 }}</h3>
                  <p v-html="privacyData.p1" />
                  <p v-html="privacyData.p2" />
                  <p v-html="privacyData.p3" />
                  <p v-html="privacyData.p4"
                     @click="openInfo(0)"
                  />

                  <div class="hidden md:block">
                    <h3>{{ privacyData.t3 }}</h3>
                    <p v-html="privacyData.p5" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <!-- info box red -->
                  <div :class="['info-box info-text', toggles[0] ? 'open' : '']">
                    <!-- <div :class="['info-box info-text']"> -->
                    <img alt="info-icon"
                         class="exclamation-mark"
                         src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-red.svg"
                    >
                    <p v-html="privacyData.pop1" />
                  </div>

                  <div class="info-box invert">
                    <h4>{{ privacyData.word0 }}</h4>
                    <p v-html="privacyData.word1" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6 md:order-1">
                <div class="block md:hidden">
                  <h3>{{ privacyData.t3 }}</h3>
                  <p v-html="privacyData.p5" />
                </div>
                <div>
                  <img style="width: 100%"
                       alt="Map with characters"
                       src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Map_with_characters.png"
                  >
                </div>
              </div>

              <div class="col-sm-12 col-md-6 md:order-none">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t4 }}</h3>
                  <p v-html="privacyData.p6" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
         BLUE CARD
        -->
        <div class="card personal-info-card">
          <div class="card-header">
            <h2>
              {{ privacyData.t5 }}<br><small>{{ privacyData.t6 }} </small>
            </h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3 v-html="privacyData.missing.m5" />
                  <p v-html="privacyData.t7" />
                  <ul>
                    <li v-html="privacyData.points.p1" />
                    <li v-html="privacyData.points.p2" />
                  </ul>
                  <p v-html="privacyData.p7" />

                  <h3>{{ privacyData.t8 }}</h3>
                  <p v-html="privacyData.p8" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <div>
                    <video style="width: 100%; border: 2px solid #0066ff; border-radius: 20px"
                           autoplay
                           muted
                           loop
                           playsinline
                           disablePictureInPicture="true"
                           preload="auto"
                           id="myVideo"
                    >
                      <source src="https://cdn.outfit7.com/web/transfered/4078c-h8v24.webm"
                              type="video/webm"
                      >
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t9 }}</h3>
                  <p v-html="privacyData.p9"
                     @click="openInfo(1)"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <div :class="['info-box info-text', toggles[1] ? 'open' : '']">
                    <img alt="info-icon"
                         class="exclamation-mark"
                         src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-blue.svg"
                    >
                    <p v-html="privacyData.pop2" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t10 }}</h3>
                  <p v-html="privacyData.p10" />
                  <p v-html="privacyData.p11" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
         PINK CARD
        -->
        <div class="card collected-info-card">
          <div id="information-that-gets-collected"
               class="card-header"
          >
            <h2>
              {{ privacyData.t11 }}
            </h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6"
                   style="margin-top: auto"
              >
                <div class="left-wrapper">
                  <div class="image-with-text">
                    <div class="image-with-title">
                      <h5>{{ privacyData.points2.title }}</h5>
                      <img alt="Mobile device"
                           src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/247-smartphone-rotate-outline.gif"
                      >
                    </div>
                    <div>
                      <ul>
                        <li class="info-after pink"
                            @click="openInfo(2)"
                            v-html="privacyData.points2.points.p1"
                        />
                        <li v-html="privacyData.points2.points.p2" />
                        <li v-html="privacyData.points2.points.p3" />
                        <li v-html="privacyData.points2.points.p4" />
                        <li class="info-after pink"
                            @click="openInfo(3)"
                            v-html="privacyData.points2.points.p5"
                        />
                      </ul>
                    </div>
                  </div>
                  <!-- mobile pink info wrapper -->
                  <div :class="['right-wrapper', toggles[2] || toggles[3] ? 'block' : 'hidden', 'md:hidden']">
                    <div :class="['info-box info-text', toggles[2] ? 'open' : '']">
                      <img alt="info-icon"
                           class="exclamation-mark"
                           src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-pink.svg"
                      >
                      <p v-html="privacyData.points2.pop1" />
                    </div>
                    <div :class="['info-box info-text', toggles[3] ? 'open' : '']">
                      <img alt="info-icon"
                           class="exclamation-mark"
                           src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-pink.svg"
                      >
                      <p v-html="privacyData.points2.pop2" />
                    </div>
                  </div>

                  <div class="image-with-text">
                    <div>
                      <div class="image-with-title">
                        <h5>{{ privacyData.points3.title }}</h5>
                        <img alt="Mobile device"
                             src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/476-controller-pad-game-play-outline.gif"
                        >
                      </div>
                    </div>
                    <div>
                      <ul>
                        <li v-html="privacyData.points3.p1" />
                        <li v-html="privacyData.points3.p2" />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- desktop pink info wrapper -->
              <div class="hidden col-sm-12 col-md-6 md:block">
                <div class="right-wrapper">
                  <div :class="['info-box info-text', toggles[2] ? 'open' : '']">
                    <img alt="info-icon"
                         class="exclamation-mark"
                         src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-pink.svg"
                    >
                    <p v-html="privacyData.points2.pop1" />
                  </div>
                  <div :class="['info-box info-text', toggles[3] ? 'open' : '']">
                    <img alt="info-icon"
                         class="exclamation-mark"
                         src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-pink.svg"
                    >
                    <p v-html="privacyData.points2.pop2" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <div class="image-with-text">
                    <div class="image-with-title">
                      <h5>{{ privacyData.points4.title }}</h5>
                      <img alt="Mobile device"
                           src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/27-globe-outline.gif"
                      >
                    </div>
                    <div>
                      <ul>
                        <li v-html="privacyData.points4.p1" />
                        <li v-html="privacyData.points4.p2" />
                        <li v-html="privacyData.points4.p3" />
                      </ul>
                    </div>
                  </div>
                  <div class="image-with-text">
                    <div class="image-with-title">
                      <h5>{{ privacyData.points5.title }}</h5>
                      <img alt="Mobile device"
                           src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/88-document-user-outline.gif"
                      >
                    </div>
                    <div>
                      <ul>
                        <li v-html="privacyData.points5.p1" />
                        <li v-html="privacyData.points5.p2" />
                      </ul>
                    </div>
                  </div>

                  <!-- <h3>{{privacyData.t12}}</h3> -->
                  <!-- <p v-html="privacyData.p12"></p> -->
                  <div class="hidden md:block">
                    <h3>{{ privacyData.t13 }}</h3>
                    <p v-html="privacyData.p13" />
                    <p class="tip"
                       v-html="privacyData.t14"
                    />
                    <p v-html="privacyData.p14" />
                    <img class="character"
                         src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Angela&Tom.png"
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <img class="block mx-auto md:hidden character"
                       src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Angela&Tom.png"
                  >
                  <div class="info-box invert">
                    <h4>{{ privacyData.phrases0 }}</h4>
                    <p v-html="privacyData.phrases1.t1" />
                    <p v-html="privacyData.phrases1.p1" />
                    <p v-html="privacyData.phrases1.t2" />
                    <p v-html="privacyData.phrases1.p2" />
                    <ul>
                      <li>{{ privacyData.phrases1.points.p1 }}</li>
                      <li>{{ privacyData.phrases1.points.p2 }}</li>
                      <li>{{ privacyData.phrases1.points.p3 }}</li>
                      <li>{{ privacyData.phrases1.points.p4 }}</li>
                    </ul>
                    <p v-html="privacyData.phrases1.p3" />
                  </div>

                  <div class="block md:hidden">
                    <h3>{{ privacyData.t13 }}</h3>
                    <p v-html="privacyData.p13" />
                    <p class="tip"
                       v-html="privacyData.t14"
                    />
                    <p v-html="privacyData.p14" />
                  </div>
                </div>
                <!-- <div class="right-wrapper">
                 <div class="info-box invert">
                 <h4>{{privacyData.phrases2.t0}}</h4>
                 <p v-html="privacyData.phrases2.t1"></p>
                 <p class="no-margin" v-html="privacyData.phrases2.p1"></p>
                 <p v-html="privacyData.phrases2.p2"></p>
                 <p class="no-margin" v-html="privacyData.phrases2.p3"></p>
                 <p v-html="privacyData.phrases2.p4"></p>
                 </div>
                 </div> -->
              </div>
            </div>
          </div>
        </div>
        <!--
         YELLOW CARD
        -->
        <div class="card the-big-picture-card">
          <div class="card-header">
            <h2>
              {{ privacyData.t15 }}<br><small>{{ privacyData.t16 }} </small>
            </h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t17 }}</h3>
                  <p class="bold no-margin"
                     v-html="privacyData.points6.t1"
                  />
                  <ul>
                    <li v-html="privacyData.points6.pts.p1" />
                    <li v-html="privacyData.points6.pts.p2" />
                    <li v-html="privacyData.points6.pts.p3" />
                  </ul>
                  <h3 id="why-collect">
                    {{ privacyData.t18 }}
                  </h3>
                  <p class="no-margin"
                     v-html="privacyData.p014"
                  />
                  <p v-html="privacyData.p15" />
                  <ul class="bolded">
                    <li @click="openInfo(4)"
                        class="info-after yellow"
                        v-html="privacyData.points7.p1"
                    />
                    <li @click="openInfo(4)"
                        class="info-after yellow"
                        v-html="privacyData.points7.p2"
                    />
                    <li @click="openInfo(4)"
                        class="info-after yellow"
                        v-html="privacyData.points7.p3"
                    />
                    <li class=""
                        v-html="privacyData.x34"
                    />
                    <li @click="openInfo(4)"
                        class="info-after yellow"
                        v-html="privacyData.points7.p4"
                    />
                    <li class=""
                        v-html="privacyData.points7.p5"
                    />
                    <li class=""
                        v-html="privacyData.points7.p6"
                    />
                    <li class=""
                        v-html="privacyData.points7.p7"
                    />
                  </ul>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <div :class="['info-box info-text', toggles[4] ? 'open' : '']">
                    <img alt="info-icon"
                         class="exclamation-mark"
                         src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-yellow.svg"
                    >
                    <p class="enumeration"
                       v-html="privacyData.points7.p1"
                    />
                    <p v-html="privacyData.pop3" />
                    <p class="enumeration"
                       v-html="privacyData.points7.p2"
                    />
                    <p v-html="privacyData.pop4" />
                    <p class="enumeration"
                       v-html="privacyData.points7.p3"
                    />
                    <p v-html="privacyData.pop5" />
                    <p class="enumeration"
                       v-html="privacyData.points7.p4"
                    />
                    <p v-html="privacyData.pop6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
         GREEN CARD
        -->
        <div class="card how-we-share-info-info-card">
          <div class="card-header">
            <h2>
              {{ privacyData.t19 }}<br><small>{{ privacyData.t20 }} </small>
            </h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t21 }}</h3>
                  <p v-html="privacyData.p16" />
                  <p v-html="privacyData.p18" />
                  <p v-html="privacyData.p19" />
                  <p v-html="privacyData.p20" />
                  <p v-html="privacyData.p17" />

                  <img class="block mx-auto md:hidden character"
                       src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Hak&Tom.png"
                  >

                  <h3>{{ privacyData.t22 }}</h3>
                  <p v-html="privacyData.p21" />

                  <h3>{{ privacyData.t23 }}</h3>
                  <p v-html="privacyData.x1" />

                  <h3>{{ privacyData.x2 }}</h3>

                  <ul class="bolded">
                    <li class="green"
                        v-html="privacyData.x3"
                    />
                    <li class="green"
                        v-html="privacyData.x4"
                    />
                    <li class="green"
                        v-html="privacyData.x5"
                    />
                    <li class="green"
                        v-html="privacyData.x6"
                    />
                  </ul>

                  <p v-html="privacyData.x7" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <img class="hidden mx-auto md:block character"
                     src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Hak&Tom.png"
                >
              </div>
            </div>
          </div>
        </div>
        <!--
         ORANGE CARD
        -->
        <div class="card what-else-happens-card">
          <div class="card-header">
            <h2>
              {{ privacyData.t24 }}<br><small>{{ privacyData.t25 }} </small>
            </h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t26 }}</h3>
                  <p v-html="privacyData.p22" />
                  <p><strong v-html="privacyData.p23" /></p>
                  <p v-html="privacyData.missing.m2" />

                  <div class="block right-wrapper md:hidden">
                    <div class="info-box invert">
                      <h4>{{ privacyData.pop14.p0 }}</h4>
                      <p v-html="privacyData.pop14.p1" />
                      <p v-html="privacyData.pop14.p2" />
                    </div>
                  </div>

                  <h3>{{ privacyData.t27 }}</h3>
                  <p v-html="privacyData.missing.m3" />
                  <ul>
                    <li v-html="privacyData.points9.p1" />
                    <li v-html="privacyData.points9.p2" />
                    <li v-html="privacyData.points9.p3" />
                  </ul>
                </div>
              </div>
              <div class="hidden md:block col-md-6">
                <div class="right-wrapper">
                  <div class="info-box invert">
                    <h4>{{ privacyData.pop14.p0 }}</h4>
                    <p v-html="privacyData.pop14.p1" />
                    <p v-html="privacyData.pop14.p2" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t28 }}</h3>
                  <p v-html="privacyData.p24" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <div class="info-box invert">
                    <h4>{{ privacyData.points10.p0 }}</h4>
                    <p v-html="privacyData.points10.p1" />
                    <p v-html="privacyData.points10.p2" />
                    <p v-html="privacyData.points10.p3" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <h3>{{ privacyData.t12 }}</h3>
                <p v-html="privacyData.p12" />
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <div class="info-box invert">
                    <h4>{{ privacyData.phrases2.t0 }}</h4>
                    <p v-html="privacyData.phrases2.t1" />
                    <p class="no-margin"
                       v-html="privacyData.phrases2.p1"
                    />
                    <p v-html="privacyData.phrases2.p2" />
                    <p class="no-margin"
                       v-html="privacyData.phrases2.p3"
                    />
                    <p v-html="privacyData.phrases2.p4" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <h3 class="dark">
                  {{ privacyData.t29 }}
                </h3>
                <p>{{ privacyData.p25 }}</p>
                <h3 class="dark">
                  {{ privacyData.ti30 }}
                </h3>
                <p v-html="privacyData.p26" />
                <p class="colored bold"
                   v-html="privacyData.points11.p0"
                />
                <p class="colored bold"
                   v-html="privacyData.points11.p1"
                />
                <h3 class="dark">
                  {{ privacyData.t30 }}
                </h3>
                <p v-html="privacyData.p27" />
              </div>
            </div>
          </div>
        </div>
        <!--
         NEW CARD !!!!!
        -->
        <div class="card important-info-card">
          <div class="card-header">
            <h2 style="max-width: 500px">
              {{ privacyData.x8 }}
            </h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <p v-html="privacyData.x9" />
                  <h3>{{ privacyData.x10 }}</h3>
                  <p v-html="privacyData.x11" />
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <div class="right-wrapper large">
                    <div :class="['info-box info-text', toggles[6] ? 'open' : '']">
                      <img alt="info-icon"
                           class="exclamation-mark img"
                           src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-green-bright.png"
                      >
                      <p v-html="privacyData.x14" />
                      <br>
                      <p v-html="privacyData.x15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.x12 }}</h3>
                  <p @click="openInfo(6)"
                     v-html="privacyData.x13"
                  />
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="right-wrapper">
                  <div class="info-box invert">
                    <h4>{{ privacyData.pop14.p0 }}</h4>
                    <p v-html="privacyData.x17" />
                    <p v-html="privacyData.x18" />
                    <p v-html="privacyData.x19" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-12">
                <h3>{{ privacyData.x20 }}</h3>
                <p v-html="privacyData.x21" />

                <p class="colored bold"
                   v-html="privacyData.x22"
                />
                <p class=""
                   v-html="privacyData.x23"
                />

                <p class="colored bold"
                   v-html="privacyData.x24"
                />
                <p class="colored bold"
                   v-html="privacyData.x25"
                />

                <h3>{{ privacyData.x26 }}</h3>
                <p v-html="privacyData.x28" />

                <h3>{{ privacyData.x37 }}</h3>
                <p v-html="privacyData.x27" />

                <p class="colored bold"
                   v-html="privacyData.x29"
                />
                <p class="colored bold"
                   v-html="privacyData.x30"
                />

                <h3>{{ privacyData.x31 }}</h3>
                <p v-html="privacyData.x32" />
              </div>
            </div>

            <div class="row" />
          </div>
        </div>
        <!--
         PURPLE CARD
        -->
        <div class="card rules-and-more-card">
          <div class="card-header">
            <h2>
              {{ privacyData.t31 }}<br><small>{{ privacyData.t32 }} </small>
            </h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t33 }}</h3>
                  <p v-html="privacyData.p28" />

                  <p class="no-margin colored bold"
                     v-html="privacyData.t34"
                  />
                  <ul class="bolded"
                      @click="openInfo(7)"
                  >
                    <li class="info-after purple"
                        v-html="privacyData.points12.p1"
                    />
                    <li class="info-after purple"
                        v-html="privacyData.points12.p2"
                    />
                    <li class="info-after purple"
                        v-html="privacyData.points12.p3"
                    />
                    <li class="info-after purple"
                        v-html="privacyData.points12.p4"
                    />
                    <li class="info-after purple"
                        v-html="privacyData.points12.p5"
                    />
                    <li class="info-after purple"
                        v-html="privacyData.points12.p6"
                    />
                    <li class="info-after purple"
                        v-html="privacyData.points12.p7"
                    />
                  </ul>

                  <div class="block right-wrapper md:hidden">
                    <div :class="['info-box info-text', toggles[7] ? 'open' : '']">
                      <img alt="info-icon"
                           class="exclamation-mark"
                           src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-purple.svg"
                      >
                      <p class="enumeration"
                         v-html="privacyData.points12.p1"
                      />
                      <p v-html="privacyData.pop15" />
                      <p class="enumeration"
                         v-html="privacyData.points12.p2"
                      />
                      <p v-html="privacyData.pop16" />
                      <p class="enumeration"
                         v-html="privacyData.points12.p3"
                      />
                      <p v-html="privacyData.pop17" />
                      <p class="enumeration"
                         v-html="privacyData.points12.p4"
                      />
                      <p v-html="privacyData.pop18" />
                      <p class="enumeration"
                         v-html="privacyData.points12.p5"
                      />
                      <p v-html="privacyData.pop19" />
                      <p class="enumeration"
                         v-html="privacyData.points12.p6"
                      />
                      <p v-html="privacyData.pop20" />
                      <p class="enumeration"
                         v-html="privacyData.points12.p7"
                      />
                      <p v-html="privacyData.missing.m4" />
                    </div>
                  </div>

                  <h3>{{ privacyData.t35 }}</h3>
                  <p v-html="privacyData.p29" />
                  <p v-html="privacyData.p30" />
                  <p class="no-margin"
                     v-html="privacyData.p31"
                  />
                  <p class="no-margin"
                     v-html="privacyData.p32"
                  />

                  <h3>{{ privacyData.t36 }}</h3>
                  <p class="bold"
                     v-html="privacyData.t37"
                  />
                  <p v-html="privacyData.p33" />
                  <p class="colored"
                     v-html="privacyData.pop21"
                  />
                </div>
              </div>
              <div class="hidden col md:block">
                <div class="right-wrapper">
                  <div :class="['info-box info-text', toggles[7] ? 'open' : '']">
                    <img alt="info-icon"
                         class="exclamation-mark"
                         src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-purple.svg"
                    >
                    <p class="enumeration"
                       v-html="privacyData.points12.p1"
                    />
                    <p v-html="privacyData.pop15" />
                    <p class="enumeration"
                       v-html="privacyData.points12.p2"
                    />
                    <p v-html="privacyData.pop16" />
                    <p class="enumeration"
                       v-html="privacyData.points12.p3"
                    />
                    <p v-html="privacyData.pop17" />
                    <p class="enumeration"
                       v-html="privacyData.points12.p4"
                    />
                    <p v-html="privacyData.pop18" />
                    <p class="enumeration"
                       v-html="privacyData.points12.p5"
                    />
                    <p v-html="privacyData.pop19" />
                    <p class="enumeration"
                       v-html="privacyData.points12.p6"
                    />
                    <p v-html="privacyData.pop20" />
                    <p class="enumeration"
                       v-html="privacyData.points12.p7"
                    />
                    <p v-html="privacyData.missing.m4" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="left-wrapper">
                  <h3>{{ privacyData.t38 }}</h3>
                  <p v-html="privacyData.p34" />
                  <ul>
                    <li v-html="privacyData.points13.p1" />
                    <li v-html="privacyData.points13.p2" />
                  </ul>

                  <img class="block mx-auto md:hidden character"
                       src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Angela&Ben.png"
                       style="margin-bottom: 40px"
                  >

                  <h3>{{ privacyData.t39 }}</h3>
                  <p v-html="privacyData.p35" />

                  <ul class="bolded"
                      @click="openInfo(8)"
                  >
                    <!-- <li class="info-after purple" v-html="privacyData.points14.p1"></li> -->
                    <li class="info-after purple"
                        v-html="privacyData.points14.p2"
                    />
                    <li class="info-after purple"
                        v-html="privacyData.points14.p3"
                    />
                    <li class="info-after purple"
                        v-html="privacyData.points14.p4"
                    />
                  </ul>

                  <div class="block right-wrapper md:hidden">
                    <div :class="['info-box info-text', toggles[8] ? 'open' : '']">
                      <img alt="info-icon"
                           class="exclamation-mark"
                           src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-purple.svg"
                      >
                      <!-- <p class="enumeration" v-html="privacyData.points14.p1"></p> -->
                      <!-- <p v-html="privacyData.pop22"></p> -->
                      <p class="enumeration"
                         v-html="privacyData.points14.p2"
                      />
                      <p v-html="privacyData.pop23" />
                      <p class="enumeration"
                         v-html="privacyData.points14.p3"
                      />
                      <p v-html="privacyData.pop24" />
                      <p class="enumeration"
                         v-html="privacyData.points14.p4"
                      />
                      <p v-html="privacyData.pop25" />
                    </div>
                  </div>

                  <h3>{{ privacyData.t40 }}</h3>
                  <p v-html="privacyData.p36" />
                  <img class="block mx-auto md:hidden character"
                       src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/toms.png"
                  >

                  <h3>{{ privacyData.t41 }}</h3>
                  <p v-html="privacyData.p37" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <img class="hidden mx-auto md:block character"
                     src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Angela&Ben.png"
                     style="margin-bottom: 40px"
                >

                <div class="hidden right-wrapper md:block">
                  <div :class="['info-box info-text', toggles[8] ? 'open' : '']">
                    <img alt="info-icon"
                         class="exclamation-mark"
                         src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-purple.svg"
                    >
                    <!-- <p class="enumeration" v-html="privacyData.points14.p1"></p> -->
                    <!-- <p v-html="privacyData.pop22"></p> -->
                    <p class="enumeration"
                       v-html="privacyData.points14.p2"
                    />
                    <p v-html="privacyData.pop23" />
                    <p class="enumeration"
                       v-html="privacyData.points14.p3"
                    />
                    <p v-html="privacyData.pop24" />
                    <p class="enumeration"
                       v-html="privacyData.points14.p4"
                    />
                    <p v-html="privacyData.pop25" />
                  </div>
                  <img class="character"
                       src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/toms.png"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
         LIGHT BLUE CARD
        -->
        <div class="card final-thoughts-card">
          <div class="card-header">
            <h2>
              {{ privacyData.t42 }}
            </h2>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="left-wrapper">
                  <h3>{{ privacyData.x33 }}</h3>
                  <p v-html="privacyData.p38" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <img class="character"
                     src="https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/ben.png"
                >
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <h3>{{ privacyData.t43 }}</h3>
                <p v-html="privacyData.p39" />
                <p class="no-margin"
                   v-html="privacyData.points15.p1"
                />
                <p class="no-margin"
                   v-html="privacyData.points15.p2"
                />
                <!-- <p class="no-margin" v-html="privacyData.points15.p3"></p> -->
                <p class="no-margin"
                   v-html="privacyData.x38"
                />
                <p class="no-margin"
                   v-html="privacyData.points15.p3"
                />
                <br>
                <p v-html="privacyData.p41" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    privacyData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      toggler: false,
      toggles: [false, false, false, false, false, false, false, false, false, false, false]
    };
  },
  mounted() {
    var acc = document.getElementsByClassName('card-header');
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var content = this.nextElementSibling;
        if (content.style.display === 'block') {
          content.style.display = 'none';
        } else {
          content.style.display = 'block';
        }
      });
    }
  },
  methods: {
    openInfo(i) {
      this.toggles[i] = !this.toggles[i];
    },
    mainToggler() {
      var acc = document.getElementsByClassName('card-header');
      var i;
      for (i = 0; i < acc.length; i++) {
        console.log(acc[i]);
        acc[i].classList.toggle('active');

        var content = acc[i].nextElementSibling;
        if (content.style.display === 'block') {
          content.style.display = 'none';
        } else {
          content.style.display = 'block';
        }
      }
      this.toggler = !this.toggler;
    }
  }
};
</script>

<style lang="scss">
img.character {
  max-width: 100%;
  max-width: 180px;
  margin: auto;
  margin-bottom: 10px;
}

.info-after {
  cursor: pointer;
}

.privacy-policy-children {
  $base-color: #ff0050;
  max-width: 1440px;
  padding-top: 16vw;
  margin: auto;

  @media screen and (min-width: 768px) {
    padding-top: 5vw;
  }

  .banner {
    display: block;
    margin: 0 auto;
    max-width: 1800px;
    height: 125px;
    max-height: 450px;
    margin-top: 13px;
    background: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/Privacy_banner_desktop.png') center;
    background-size: cover;
    position: relative;

    @media screen and (min-width: 620px) {
      height: 185px;
    }

    @media screen and (min-width: 767px) {
      margin-top: 0px;
    }

    @media screen and (min-width: 920px) {
      height: 0;
      padding-top: 21.4285%;
    }

    .cta-play-now {
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      position: absolute;
      font-size: 25px;
      line-height: 36px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #0066ff;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      width: 260px;
      height: 52px;
      text-transform: uppercase;
      color: #ffffff;
      bottom: 28px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .content-wrapper {
    padding: 0 10px 40px 10px;

    .main-toggler {
      cursor: pointer;
      font-family: 'Oswald', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: #ff0050;
      text-transform: uppercase;
      max-width: 767px;
      margin: auto;
      margin-bottom: 10px;
      justify-content: flex-end;

      .arrow-toggler {
        width: 12px;
        height: 12px;
        margin-right: 10px;
        margin-top: 5px;
        margin-left: 6px;
      }
    }

    h1,
    h2,
    h3,
    h4 {
      text-transform: uppercase;
    }

    & h1 {
      font-family: 'Oswald', sans-serif;
      margin-top: 80px;
      margin-bottom: 73px;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      line-height: 74px;
      color: $base-color;
    }

    & h2 {
      font-family: 'Oswald', sans-serif;
      font-style: normal;
      font-weight: 600 !important;
      font-size: 26px;
      line-height: 39px;
      display: flex;
      align-items: center;
      text-align: center;
      margin: 0;
      flex-grow: 0;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0;
      -webkit-flex-wrap: wrap;
      justify-content: center;

      & small {
        font-weight: 600;
        text-transform: none;
        font-family: 'Oswald', sans-serif;
        line-height: 29px;
      }
    }

    & h3 {
      margin-bottom: 25px;
      font-family: 'Oswald', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;

      &.dark {
        // color: #281E3C;
        margin-top: 50px;
        padding-top: 0 !important;
      }
    }

    & h4 {
      font-family: 'Oswald', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 17px;
      color: white;
    }

    .card {
      max-width: 767px;
      border: none;
      margin: auto;
      margin-bottom: 35px;

      .card-header {
        display: flex;
        justify-content: center;
        padding: 28px 10px 32px 10px;
        text-align: center;
        border-radius: 20px;
        position: relative;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          background: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/arrow-policy.png') no-repeat;
          background-size: 100%;
          top: 41%;
          right: 30px;
          height: 29px;
          width: 25px;
          z-index: 99;
        }

        // &.active {
        //   &::after {
        //     -webkit-animation-name: spin;
        //     -webkit-animation-duration: 1000ms;

        //     -webkit-animation-timing-function: linear;

        //     -moz-animation-name: spin;
        //     -moz-animation-duration: 1000ms;
        //     -moz-animation-timing-function: linear;

        //     -ms-animation-name: spin;
        //     -ms-animation-duration: 1000ms;
        //     -ms-animation-timing-function: linear;

        //     animation-name: spin;
        //     animation-duration: 100ms;
        //   }
        // }
      }

      .card-content {
        padding: 100px 25px 10px 25px;
        border-radius: 0 0 20px 20px;
        border-style: solid;
        border-width: 2px;
        margin-top: -20px;
        border-top: none;
        display: none;

        p {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          color: #281e3c;
          margin-bottom: 20px;

          &.tip {
            margin-bottom: 0;
            font-weight: bold;
          }

          &.no-margin {
            margin-bottom: 0;
          }

          &.bold {
            font-weight: bold;
          }
        }

        ul {
          padding: 0 0 0 15px;
          list-style: none;

          &.bolded {
            padding: 0 0 0 15px;

            li {
              font-weight: 600;
            }
          }

          li {
            font-family: 'Work Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            color: #281e3c;

            &:before {
              content: '\2022';
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }
        }

        .image-with-text {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .image-with-title {
            max-width: 105px;
            position: relative;
            display: flex;
            flex-direction: column;

            h5 {
              margin: 0;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
            }
          }
        }

        .left-wrapper {
          max-width: 335px;
        }

        .right-wrapper {
          max-width: 220px;
          margin: auto;

          &.large {
            max-width: 321px;
          }

          .info-box {
            position: relative;
            box-sizing: border-box;
            border-radius: 20px;
            margin-bottom: 40px;
            border-style: solid;
            border-width: 2px;

            &.info-text {
              visibility: hidden;

              &.open {
                visibility: visible;
              }
            }

            img {
              &.exclamation-mark {
                position: absolute;
                top: -17px;
                right: -19px;

                &.img {
                  width: 37px;
                }
              }
            }

            p {
              font-family: 'Work Sans', sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 17px;
              align-items: center;
              margin-bottom: 0;

              &:first-child {
                margin-top: 0 !important;
              }

              &.enumeration {
                font-weight: bold;
                margin-top: 20px;
              }

              &.no-margin {
                margin-bottom: 0;
              }
            }

            padding: 20px;

            &.invert {
              padding: 15px 20px 15px 20px;

              p {
                margin-bottom: 20px;

                &.no-margin {
                  margin-bottom: 0;
                }
              }

              h4 {
                margin-bottom: 20px;
              }

              ul {
                padding: 0;
              }
            }
          }
        }
      }
    }

    @mixin iconColor() {
      content: '';
      width: 13px;
      height: 14px;
      top: 3px;
      left: 3px;
      position: relative;
      display: inline-block;
      background-position: center;
      background-position-x: center;
      background-position-y: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .info-after {
      &.red {
        &:after {
          @include iconColor();
          background-image: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-red.svg');
        }
      }

      &.blue {
        &:after {
          @include iconColor();
          background-image: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-blue.svg');
        }
      }

      &.pink {
        &:after {
          @include iconColor();
          background-image: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-pink.svg');
        }
      }

      &.yellow {
        &:after {
          @include iconColor();
          background-image: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-yellow.svg');
        }
      }

      &.green {
        &:after {
          @include iconColor();
          background-image: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-green.svg');
        }
      }

      &.light-green {
        &:after {
          @include iconColor();
          background-image: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-green-bright.png');
        }
      }

      &.purple {
        &:after {
          @include iconColor();
          background-image: url('https://cdn.outfit7.com/web/talking-tom-and-friends/children-privacy/info-purple.svg');
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .privacy-policy-children {
    .banner {
      .cta-play-now {
        position: absolute;
        font-size: 12px;
        line-height: 18px;
        width: 92px;
        height: 18px;
        bottom: 9px;
      }
    }

    .content-wrapper {
      h1 {
        padding: 0 18%;
      }

      .main-toggler {
        cursor: pointer;
        font-family: 'Oswald', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #ff0050;
        text-transform: uppercase;
        max-width: 767px;
        margin: auto;
        margin-bottom: 10px;
        justify-content: center;

        .arrow-toggler {
          width: 10px;
          height: 10px;
          margin-right: 0px;
          margin-top: 4px;
          margin-left: 6px;
        }
      }

      & h1 {
        font-size: 30px;
        line-height: 44px;
      }

      & h2 {
        font-size: 26px;
        line-height: 39px;

        & small {
          line-height: 29px;
        }
      }

      & h3 {
        font-size: 24px;
        line-height: 36px;
      }

      & h4 {
        font-size: 16px;
        line-height: 17px;
      }

      .card {
        margin-bottom: 29px;

        .card-header {
          padding: 28px 10px 32px 10px;
          border-radius: 20px;

          &::after {
            display: none;
          }
        }

        .card-content {
          padding: 80px 25px 60px 25px;
          position: relative;
          top: -20px;
          margin-top: unset;
          display: none;

          p {
            font-size: 12px;
            line-height: 17px;
          }

          ul {
            padding: 0 15px;

            li {
              font-size: 12px;
              line-height: 17px;

              &:before {
                width: 1em;
                margin-left: -1em;
              }
            }
          }

          .image-with-text {
            flex-direction: column;

            .image-with-title {
              flex-direction: column;
              margin: auto;

              h5 {
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
              }
            }
          }

          .left-wrapper {
            max-width: 100%;
          }

          .right-wrapper {
            max-width: 100%;
            margin: auto;

            &.large {
              max-width: 321px;
            }

            .info-box {
              border-radius: 20px;
              margin-bottom: 40px;

              &.info-text {
                display: none;

                &.open {
                  display: block;
                }
              }

              img {
                &.exclamation-mark {
                  top: -17px;
                  right: -19px;
                }
              }

              p {
                font-size: 12px;
                line-height: 17px;

                &:first-child {
                  margin-top: 0 !important;
                }
              }

              padding: 20px;

              &.invert {
                padding: 15px 20px 15px 20px;

                p {
                  margin-bottom: 20px;

                  &.no-margin {
                    margin-bottom: 0;
                  }
                }

                h4 {
                  margin-bottom: 20px;
                }

                ul {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

// mixin for cards
@mixin cardStyle($color) {
  border-radius: 20px 20px 0px 0px;

  h3,
  h5,
  a,
  i {
    color: $color;
  }

  h3 {
    &:not(:first-child) {
      padding-top: 40px;
    }
  }

  .col {
    &:first-child {
      padding-bottom: 40px;
    }
  }

  .card-header {
    background: $color;
  }

  .card-content {
    border-color: $color;

    ul {
      li {
        &:before {
          color: $color;
        }
      }
    }

    p {
      &.colored {
        color: $color;
      }
    }

    strong {
      &.colored {
        color: $color;
      }
    }

    .info-box {
      border-color: $color;

      p {
        color: $color;
      }

      &.invert {
        background: $color;

        p {
          color: white;
        }

        ul {
          li {
            color: white;

            :before {
              color: white;
            }
          }
        }
      }
    }
  }
}

.privacy-policy-children {
  .introduction-card {
    @include cardStyle(#ff0050);
  }

  .personal-info-card {
    @include cardStyle(#0066ff);
  }

  .collected-info-card {
    @include cardStyle(#ff8078);
  }

  .the-big-picture-card {
    @include cardStyle(#ffb600);
  }

  .how-we-share-info-info-card {
    @include cardStyle(#00c832);
  }

  .what-else-happens-card {
    @include cardStyle(#ff741e);
  }

  .important-info-card {
    @include cardStyle(#44e197);
  }

  .rules-and-more-card {
    @include cardStyle(#9b30ef);
  }

  .final-thoughts-card {
    @include cardStyle(#00afdc);
  }
}

// Tailwind utilities overrides
.invert {
  filter: unset;
}

b,
strong {
  font-weight: unset;
}

h2 {
  color: #ffffff;
}

// Utilities from Bootstrap
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
